import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);
// history: createWebHistory(),
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "/database",
    name: "Database",
    component: () =>
      import(/* webpackChunkName: "database" */ "../views/Database.vue")
  },
  {
    path: "/glossary",
    name: "Glossary",
    component: () =>
      import(/* webpackChunkName: "aiobservatory" */ "../views/Glossary.vue")
  },
  {
    path: "/context",
    name: "Context",
    component: () =>
      import(/* webpackChunkName: "Context" */ "../views/Context.vue")
  },
  {
    path: "/admsharms",
    name: "Admsharms",
    component: () =>
      import(/* webpackChunkName: "Admsharms" */ "../views/Admsharms.vue")
  },
  {
    path: "/reclaiming",
    name: "Reclaiming AI Futures",
    component: () =>
      import(/* webpackChunkName: "Reclaiming AI Futures" */ "../views/Reclaiming.vue")
  },
  {
    path: "/takeaction",
    name: "Takeaction",
    component: () =>
      import(/* webpackChunkName: "Takeaction" */ "../views/Takeaction.vue")
  },
  {
    path: "/artwork-1",
    name: "Reclaiming AI Futures for Economic Planning",
    component: () =>
      import(/* webpackChunkName: "artwork-1" */ "../views/artwork-1.vue")
  },
  {
    path: "/artwork-2",
    name: "AlgoLeela",
    component: () =>
      import(/* webpackChunkName: "artwork-2" */ "../views/artwork-2.vue")
  },
  {
    path: "/artwork-3",
    name: "I am AI 2.3",
    component: () =>
      import(/* webpackChunkName: "artwork-3" */ "../views/artwork-3.vue")
  },
  {
    path: "/artwork-4",
    name: "This Scene May Contain ______",
    component: () =>
      import(/* webpackChunkName: "artwork-4" */ "../views/artwork-4.vue")
  },
  {
    path: "/artwork-5",
    name: "your dataset won't let me thrive / your dataset must die",
    component: () =>
      import(/* webpackChunkName: "artwork-5" */ "../views/artwork-5.vue")
  },
  {
    path: "/artwork-6",
    name: "Word!",
    component: () =>
      import(/* webpackChunkName: "artwork-6" */ "../views/artwork-6.vue")
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;

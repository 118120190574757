<template>
  <!-- <b-container> -->
    <Landing></Landing>
  <!-- </b-container> -->
</template>

<script>
import Landing from "@/components/Landing-Content.vue";
export default {
  name: "Home",

  components: {
    Landing: Landing
  }
};
</script>
